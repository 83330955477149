import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Image from "~components/image/image"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Slideshow from "~components/slideshow/slideshow"
import YoutubeEmbed from "~components/youtube/youtube"

/* Import Local Components */
import PostHeader from "./components/postHeader/postHeader"

/* Import Local Style(s) */
import "./post.scss"

const Post = ({ location, data: { sanityNewsPost: newsPost } }) => {
  const { image, youtubeOverride, slideshow, _rawBody } = newsPost

  // console.log(youtubeOverride, slideshow, _rawBody)

  return (
    <Page title="Post" location={location} className="template">
      <Helmet>
        <style>{`body { background-color: var(--color-lightest-gray); }`}</style>
      </Helmet>
      <PostHeader post={newsPost} location={location} />
      <PageArticle title="Post" className="post">
        <PageArticleSection className="post">
          { slideshow && slideshow.length > 0 ? <Slideshow 
            slideshow={
              {
                images: slideshow.map(s => s.image ?  image : s.youtubeOverride ? { youtubeOverride } : null).filter(s => s != null), 
                delay: slideshow.filter(s => s.youtubeOverride && s.youtubeOverride != '').length > 0 ? 4 : 2,
              }
            } 
            pauseOnHover={slideshow.filter(s => s.youtubeOverride && s.youtubeOverride != '').length > 0 ? true : false}
            hasVideo={slideshow.filter(s => s.youtubeOverride && s.youtubeOverride != '').length > 0 ? true : false}
            />
              :
              youtubeOverride && youtubeOverride != '' ? <div className="mb--1"><YoutubeEmbed embed={youtubeOverride} /></div> 
              :
              image ? <Image image={image.asset.gatsbyImageData} />
              : null
          }
          <div className="news_text">
            <BlockContent blocks={_rawBody} />  
          </div>
        </PageArticleSection>
      </PageArticle>
    </Page>
  )
}

export default Post

export const query = graphql`
  query ($id: String!) {
    sanityNewsPost(id: { eq: $id }) {
      ...fieldsOnNewsPostDocument
    }
  }
`
